import Book from "../models/Book";

export default class BookParser {
    public static getBook = (doc: Document): Book | undefined => {
        if (doc) {
            //<MNBeaubourg version="5.0" xml:space="preserve" xmlns:glo="http://com.editis.sejer/dtd/glo" xmlns:som="http://com.editis.sejer/dtd/som" xmlns:mt="http://com.editis.sejer/dtd/mt" xmlns:voc="http://com.editis.sejer/dtd/voc" xmlns:ref="http://com.editis.sejer/dtd/ref" id="FI_F6" couverture="FI_F6_C.jpg" vignette="FI_F6_V.jpg" calculatrice="1" clavierVirtuel="1" backgroundcolor="LimeGreen" editMathActiv="1">
            var rootNode = doc.querySelector("MNBeaubourg");
            if (rootNode) {
                var metaPub = rootNode.querySelector("metaPub");
                var pageVraiTitre = rootNode.querySelector("pageVraiTitre");
                if (metaPub && pageVraiTitre) {
                    const shortCode = rootNode.attributes.getNamedItem("id")?.value;
                    const coverUrl = rootNode.attributes.getNamedItem("couverture")?.value;
                    const ean = metaPub.attributes.getNamedItem("ean13")?.value;
                    let name = null;
                    let subTitle = "";
                    for (let i = 0; i < metaPub.children.length; i++) {
                        if (metaPub.children[i].localName === "titre") {
                            if (!metaPub.children[i].hasAttribute("type") || metaPub.children[i].attributes.getNamedItem("type")?.value === "ti") {
                                name = metaPub.children[i].innerHTML;
                            }
                            if (metaPub.children[i].attributes.getNamedItem("type")?.value === "ssti1") {
                                subTitle = metaPub.children[i].innerHTML || "";
                            }
                        }
                    }
                    if (shortCode && ean && name && coverUrl) {
                        return {
                            id: ean,
                            shortCode,
                            name,
                            subTitle,
                            coverUrl,
                        };
                    }
                }
            }
        }

        return undefined;
    };
}
