import { useState, useMemo, useEffect } from 'react';
import { FormControl, Typography, CircularProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone'
import { ContainerClient } from '@azure/storage-blob';
import Alert from '@material-ui/lab/Alert/Alert';
import useStyles from '../../common/UseStyles';
import Book from '../../models/Book';
import UploadSessionItem from '../../models/UploadSessionItem';
import CommonApi from '../../api/CommonApi';
import UploadInfo from '../../models/UploadInfo';
import DoneIcon from "@material-ui/icons/Done";
import { v4 as uuidv4 } from 'uuid';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '100px 20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
} as React.CSSProperties;

const focusedStyle = {
    borderColor: '#2196f3'
} as React.CSSProperties;

const acceptStyle = {
    borderColor: '#00e676'
} as React.CSSProperties;

const rejectStyle = {
    borderColor: '#ff1744'
} as React.CSSProperties;

interface EditBookProps {
    book: Book;
    bookInstance: string;
    setInfoMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
    uploadInfo: UploadInfo;
}

const EditBook = (props: EditBookProps) => {
    const { book, bookInstance, setInfoMessage, uploadInfo } = props;
    const [errors, setErrors] = useState<string[]>();
    const [containerClient, setContainerClient] = useState<ContainerClient>();
    const [uploadSessionHistory, setUploadSessionHistory] = useState<UploadSessionItem[]>([]);
    const styleClasses = useStyles();

    const onDrop = (acceptedFiles: File[]) => {
        if (!containerClient) return [];

        const uploadFile = (file: File) => {
            return new Promise<boolean>((resolve, reject) => {
                if (!containerClient)
                    reject(file.name);
                else {

                    CommonApi.uploadFile(containerClient, file)
                        .then(() => {
                            resolve(true);
                        })
                        .catch(() => {
                            resolve(false);
                        });
                }
            });
        };

        const bytesToSize = (bytes: number) => {
            var sizes = ['o', 'Ko', 'Mo', 'Go', 'To'];
            if (bytes === 0) return '0 o';
            var i = parseInt((Math.floor(Math.log(bytes) / Math.log(1024))).toString());
            return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
        }

        const session: UploadSessionItem[] = acceptedFiles.map((f) => {
            const curSessionItem = {
                id: uuidv4(),
                startDate: new Date(),
                name: f.name,
                size: bytesToSize(f.size),
                status: "En attente"
            } as UploadSessionItem;

            return curSessionItem;
        });

        setInfoMessage(undefined);
        setUploadSessionHistory(session);

        acceptedFiles.reduce<Promise<UploadSessionItem | undefined | void>>((prevVal, currentVal, curIdx, files) => {
            return new Promise<UploadSessionItem | undefined>((resolve, reject) => {
                prevVal.then(() => {
                    const curSessionItemIdx = session.findIndex((s) => s.name === currentVal.name);
                    if (curSessionItemIdx < 0) {
                        resolve(undefined);
                    }
                    else {
                        const curSessionItem = session[curSessionItemIdx];
                        curSessionItem.status = "En cours";
                        // update current session with previous
                        setUploadSessionHistory([...session.slice(0, curSessionItemIdx), curSessionItem, ...session.slice(curSessionItemIdx + 1)]);

                        uploadFile(currentVal).then((res) => {
                            curSessionItem.endDate = new Date();
                            curSessionItem.status = res ? "Terminé" : "Error";

                            resolve(curSessionItem);
                        })
                            .catch(() => {
                                curSessionItem.endDate = new Date();
                                curSessionItem.status = "Error";

                                resolve(curSessionItem);
                            }).finally(() => {
                                setUploadSessionHistory([...session.slice(0, curSessionItemIdx), curSessionItem, ...session.slice(curSessionItemIdx + 1)]);
                                if (curIdx === files.length - 1) // last item
                                {
                                    const msg = `Upload terminé pour le manuel ${book.shortCode} (${files.length} fichier${files.length > 1 ? "s" : ""})`;
                                    const hasXml = files.find(f => f.name.indexOf(".xml") > 0) !== undefined;
                                    if (hasXml && bookInstance && book) {
                                        CommonApi.resetBookCache(bookInstance, book)
                                            .then((result) => {
                                                if (result.data) {
                                                    setInfoMessage(`${msg} et cache mis à jour`);
                                                }
                                                else {
                                                    setInfoMessage(undefined);
                                                }
                                            });
                                    } else {
                                        setInfoMessage(msg);
                                    }
                                }
                            });
                    }

                });
            });
        }, Promise.resolve());
    };

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
    } = useDropzone({ onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        setContainerClient(uploadInfo ? new ContainerClient(`${uploadInfo.baseUrl}${uploadInfo.query}`) : undefined);
    }, [uploadInfo]);

    return (
        <FormControl fullWidth>
            <Typography dangerouslySetInnerHTML={{ __html: `Titre : ${book.name}` }}></Typography>
            <Typography>EAN : {book.id}</Typography>
            <Typography>Code court : {book.shortCode}</Typography>
            <hr />
            <Typography style={{ fontWeight: 'bold' }}>Mise à jour des fichiers i-Manuel (XML et/ou ressources)</Typography>
            {containerClient && (
                <>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Déposez les fichiers à uploader ici...</p> :
                                <p>Vous pouvez le faire par drag'n drop ou en cliquant pour sélectionner les fichiers</p>
                        }
                    </div>
                    {uploadSessionHistory && uploadSessionHistory.length > 0 && (
                        <div style={{ marginTop: 10 }}>
                            Suivi de l'upload:
                            <ul>
                                {uploadSessionHistory.map((s) =>
                                (<li key={s.id} style={{ fontWeight: (s.status === "En cours" ? "bold" : "normal") }}>
                                    {s.name} ({s.size}) - <span className={`$upload_status {s.status}`}>{s.status} </span>
                                    {s.status === "En cours" && (<CircularProgress size={16} color="primary" />)}
                                    {s.status === "Terminé" && s.endDate && (<><DoneIcon fontSize="small" htmlColor="green" /> <span> - Durée: {Math.round((s.endDate.getTime() - s.startDate.getTime()) / 1000).toString()}s</span></>)}
                                </li>))}
                            </ul>
                        </div>
                    )}
                </>
            )}
            {errors && errors.map((err) => {
                return (<Alert severity="error" className={styleClasses.alert}>{err}</Alert>)
            })}
        </FormControl>
    );
}

export default EditBook;