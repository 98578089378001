import React, {useContext} from "react";
import {Redirect, Route} from "react-router-dom";
import AppContext from "../../contexts/AppContext";

export interface AuthenticatedRouteProps {
  path: string;
}

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({children, ...props}) => {
  const appContext = useContext(AppContext);

  return (
    <Route
      {...props}
      render={({location}) =>
        appContext.currentUser ? children : <Redirect to={{pathname: "/Login", state: {from: location}}} />
      }
    />
  );
};

export default AuthenticatedRoute;
