import React, { useEffect, useContext } from "react";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import AppContext from "../../contexts/AppContext";

export interface TimesheetProps { }

type TimesheetParams = {
  instance: string;
  bookId: string;
}

const Timesheet: React.FC<TimesheetProps> = (props: TimesheetProps) => {
  const { instance, bookId } = useParams<TimesheetParams>();

  const appContext = useContext(AppContext);
  useEffect(() => {
    appContext.setTitle('Gestion du temps');
  }, [appContext]);

  return (
    <Box px={4}>
      <timesheet-widget src={`/api/timesheet/${instance}/${bookId}`}></timesheet-widget>
    </Box>
  );
};

export default Timesheet;
