import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert/Alert';
import React, { useEffect, useState } from 'react';
import useStyles from '../../common/UseStyles';
import UsersManagementApi from "../../api/UsersManagementApi";
import ErrorHelper from '../../common/ErrorHelper';
import generatePassword from '../../api/PasswordGenerator';
import CircularProgress from "@material-ui/core/CircularProgress";

interface CreateUserProps {
    userCreated: () => void;
}

const CreateUser: React.FC<CreateUserProps> = (props: CreateUserProps) => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>(generatePassword(10));
    const [errors, setErrors] = useState<string[]>();
    const styleClasses = useStyles();

    useEffect(() => {
        if (!initialized) {            
            setInitialized(true); 
        }
    }, [initialized]);

    const openDialog = () => {
        setShowDialog(true);
    }
    const closeDialog = () => {
        setShowDialog(false);
    }
    const refreshUsers = () => {
        setErrors([]);
        UsersManagementApi.createUser({
            email,
            password,
            username,
            role: "user",
        }).then((res) => {
            if (res.data.length > 0) {
                if (res.data[0] === 'Success') {
                    closeDialog();
                    setUsername('');
                    setEmail('');
                    setPassword(generatePassword(10));
                    props.userCreated();
                }
                else {
                    setErrors(ErrorHelper.getErrorMessages(res.data));
                }
            }
        });
    }

    return (
        <>
            <Button variant="contained" color="primary" onClick={openDialog}>Nouvel utilisateur</Button>
            <Dialog open={showDialog} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Création d'un nouvel utilisateur</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez saisir les informations suivantes
                    </DialogContentText>
                    {!initialized && (
                        <CircularProgress />
                    )}
                    {initialized && (
                        <>
                            <TextField
                                autoFocus
                                margin="normal"
                                id="username"
                                value={username}
                                onChange={(event) => {
                                    setUsername(event.target.value);
                                }}
                                label="Identifiant"
                                type="text"
                                fullWidth
                            />
                            <TextField
                                margin="normal"
                                id="email"
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                label="Adresse email"
                                type="email"
                                fullWidth
                            />
                            <TextField
                                margin="normal"
                                id="password"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                                label="Mot de passe"
                                type="text"
                                fullWidth
                            />
                        </>
                    )}
                    {errors && errors.map((err) => {
                        return (<Alert severity="error" className={styleClasses.alert}>{err}</Alert>)
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="secondary">Annuler</Button>
                    <Button onClick={refreshUsers} variant="contained" disabled={!initialized} color="primary">Créer</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CreateUser;