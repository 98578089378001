import React from 'react';
import Grid from "@material-ui/core/Grid";
import useStyles from '../../common/UseStyles';
import Link from '@material-ui/core/Link';

export interface FooterProps { }

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
    const styleClasses = useStyles();
    return (
        <>
            <Grid  
            container
            direction="row"
            justify="center"
            alignItems="center"
            data-html2canvas-ignore="true"
            className={styleClasses.footer}>
                <Grid item className={styleClasses.footerItem} xs>
                    <Link href="/" className={styleClasses.footerLink}>
                        <img src="/img/nathan.svg" alt="logo" />
                    </Link>
                </Grid>
                <Grid item xs>
                    <Link href="https://editions.nathan.fr/mentions-legales" className={styleClasses.footerLink}>Mentions légales</Link>
                </Grid>
                <Grid item xs>
                    <Link href="/conditions-generales-utilisation" className={styleClasses.footerLink}>Conditions générales d'utilisations</Link>
                </Grid>
                <Grid item xs>
                    <Link href="https://editions.nathan.fr/questions-reponses" className={styleClasses.footerLink}>Contact</Link>
                </Grid>
            </Grid>
        </>
    );
};

export default Footer;