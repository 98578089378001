import React, {useContext, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import useStyles from "../../common/UseStyles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppContext from "../../contexts/AppContext";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import TimerIcon from "@material-ui/icons/Timer";
import ViewListIcon from '@material-ui/icons/ViewList';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import {Button} from "@material-ui/core";

export interface TopBarProps {}

const TopBar: React.FC<TopBarProps> = () => {
  const appContext = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const styleClasses = useStyles();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };

  const handleLogout = () => {
    document.location.assign(`/Identity/Account/Logout?returnUrl=${encodeURIComponent("/logout")}`);
  };

  return (
    <>
      <AppBar position="static" className={styleClasses.appBar}>
        {appContext && (
          <Toolbar>
            <Link href="/" className={styleClasses.appBarLink} >
              <img src="/img/nathan.svg" alt="logo" />
            </Link>
            <Link href="https://imanuel.nathan.fr/" className={styleClasses.appBarLink} target="_blank">
              <img src="/img/imanuel.svg" alt="logo" />
            </Link>
            <Typography variant="h6" className={styleClasses.appBarTitle}>{appContext.title}</Typography>
            <Typography variant="button" color="textPrimary" className={styleClasses.usernameLink} data-html2canvas-ignore="true">
              <b>{appContext.currentUser?.username}</b>
            </Typography>
            <Button onClick={handleLogout} data-html2canvas-ignore="true">DÉCONNEXION</Button>
            <IconButton component={RouterLink} to="/" data-html2canvas-ignore="true">
              <HomeIcon />
            </IconButton>
            <IconButton component={RouterLink} to="/timesheet" data-html2canvas-ignore="true">
              <TimerIcon />
            </IconButton>
            <IconButton component={RouterLink} to="/meta" data-html2canvas-ignore="true">
              <ViewListIcon />
            </IconButton>
            {appContext.currentUser?.role === "admin" && (
            <IconButton aria-label="menu" aria-controls="main-menu" onClick={handleMenuClick} data-html2canvas-ignore="true">
              <MenuIcon />
            </IconButton>
            )}
          </Toolbar>
        )}
      </AppBar>

      {appContext.currentUser?.role === "admin" && (
        <Menu id="main-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem component={RouterLink} to="/admin/users" onClick={handleMenuClose}>
            Gestion des utilisateurs
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default TopBar;
