import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "30px",
    minHeight: "calc(100vh - 118px)",
    backgroundColor: "#F3F3F3",
  },
  autocomplete: {
    width: 550,
  },
  title: {
    margin: theme.spacing(4, 2, 2),
  },
  formControl: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  usersList: {},
  appBar: {
    background: "#fff !important",
    boxShadow: "0 3px 3px rgba(0,0,0,.08)",
    height: "65px",
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#000 !important",
  },
  appBarTitle: {
    flexGrow: 1,
    textAlign: 'center',
    color: "#000",
  },
  appBarLink: {
    marginRight: "15px !important",
  },
  analyticsTable: {
    boxShadow: "0 3px 3px rgba(0,0,0,.08)",
    background: "#FFF !important",
  },
  footer: {
    background: "#000 !important",
    minHeight: 53,
  },
  footerItem: {
    textAlign: "center",
  },
  footerLink: {
    color: "#FFF !important",
  },
  logoutContainer: {
    padding: 30,
    background: "#FFF !important",
    boxShadow: "0 3px 3px rgba(0,0,0,.08)",
  },
  pdfContainer: {
  },
  pdfButton: {
    marginLeft: "10px !important",
  },
  usernameLink: {
    paddingRight: 5,
    fontWeight: 700
  },
  selectRemoveAllButton: {
    fontSize: 12
  },
  tableHead: {
    backgroundColor: '#d32f2f',
  },
  tableHeadCell: {
    border: '1px solid #000',
    color: '#FFF',
    fontWeight: 700,
  },
  tableCell: {
    border: '1px solid #000',
    textAlign: 'center'
  },
}));

export default useStyles;
