import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import User from '../../models/User';
import UsersManagementApi from '../../api/UsersManagementApi';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import useStyles from '../../common/UseStyles';

interface DeleteUserProps {
    done: () => void;
    canceled: () => void;
    selectedUser: User | undefined;
}

const DeleteUser: React.FC<DeleteUserProps> = (props: DeleteUserProps) => {
    const [errors, setErrors] = useState<string[]>();
    const styleClasses = useStyles();

    const closeDialog = () => {
        props.done();
    }
    
    const deleteUser = () => {
        setErrors([]);
        if (props.selectedUser)
        {
            UsersManagementApi.deleteUser(props.selectedUser.username)
            .then((res) => {
                if (res.data.length > 0) {
                    if (res.data[0] === 'Success') {                        
                        props.done();
                    }
                    else {
                        let errorMessages: string[] = [];
                        res.data.forEach((err) => {
                            switch (err) {
                                case 'DefaultError':
                                case 'ConcurrencyFailure':
                                    errorMessages.push('Une erreur est survenue');
                                    break;
                                case 'PasswordMismatch':
                                    errorMessages.push('Les mots de passe ne correspondent pas');
                                    break;
                                case 'LoginAlreadyAssociated':
                                case 'DuplicateUserName':
                                    errorMessages.push('Identifiant déjà utilisé');
                                    break;
                                case 'InvalidUserName':
                                    errorMessages.push('Identifiant invalide');
                                    break;
                                case 'InvalidEmail':
                                    errorMessages.push('Adresse email invalide');
                                    break;
                                case 'DuplicateEmail':
                                    errorMessages.push('Adresse email déjà utilisée');
                                    break;
                                case 'PasswordTooShort':
                                    errorMessages.push('Le mot de passe est trop court');
                                    break;
                                case 'PasswordRequiresNonAlphanumeric':
                                    errorMessages.push('Le mot de passe doit contenir au moins un caractère non alphanumérique');
                                    break;
                                case 'PasswordRequiresDigit':
                                    errorMessages.push('Le mot de passe doit contenir au moins un caractère numérique');
                                    break;
                                case 'PasswordRequiresLower':
                                    errorMessages.push('Le mot de passe doit contenir au moins une minuscule');
                                    break;
                                case 'PasswordRequiresUpper':
                                    errorMessages.push('Le mot de passe doit contenir au moins une majuscule');
                                    break;
                                default:
                                    errorMessages.push('Une erreur est survenue');
                                    break;
                            }
                        });
    
                        setErrors(errorMessages);
                    }
                }
            });
        }
    }

    return (
        <Dialog open={props.selectedUser !== undefined} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Suppression de compte</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Vous êtes sur le point de supprimer le compte <b>{props.selectedUser?.username}</b>.<br/>
                    Cette action est irréversible. 
                </DialogContentText>   
                {errors && errors.map((err) => {
                        return (<Alert severity="error" className={styleClasses.alert}>{err}</Alert>)
                    })}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="secondary">Annuler</Button>
                <Button onClick={deleteUser} variant="contained" color="primary">Confirmer la suppression</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteUser;