import React, { useState, useEffect, useCallback, useContext } from "react";
import { Box, TextField, Grid, Button, Typography, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import useStyles from '../../common/UseStyles';
import CommonApi from "../../api/CommonApi";
import Book from "../../models/Book";
import UploadInfo from "../../models/UploadInfo";
import BookParser from "../../helpers/BookParser";
import EditBook from "../../components/editBook/EditBook";
import AddBook from "../../components/addBook/AddBook";
import AppContext from "../../contexts/AppContext";

export interface HomeProps { }

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [booksInstances, setBooksInstances] = useState<string[]>();
  const [selectedBooksInstance, setSelectedBooksInstance] = useState<string>();
  const [books, setBooks] = useState<Book[]>();
  const [selectedBook, setSelectedBook] = useState<Book>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loadingBooks, setLoadingBooks] = useState<boolean>(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errors, setErrors] = useState<string[]>();
  const [uploadInfo, setUploadInfo] = useState<UploadInfo>();
  const styleClasses = useStyles();


  const handleBooksInstanceChange = (instance: string | undefined) => {
    console.log("handleBooksInstanceChange");
    setUploadInfo(undefined);
    setSelectedBook(undefined);
    setBooks([]);
    setInfoMessage(undefined);
    setSelectedBooksInstance(instance);
  };

  const bookAdded = useCallback((book: Book) => {
    if (selectedBooksInstance) {
      setSelectedFile(undefined);
      setLoadingBooks(true);
      CommonApi.getBooks(selectedBooksInstance)
        .then((resBooks) => {
          resBooks.data.forEach(b => {
            // Remove HTML
            var tempElement = document.createElement("div");
            tempElement.innerHTML = b.name;
            b.name = tempElement.textContent || tempElement.innerText || "";
          });
          setBooks(resBooks.data);
          setInfoMessage(`Upload terminé, nouveau manuel ajouté ${book.shortCode}`);
        })
        .catch((reason) => {
          if (reason.response && (reason.response.status === 401 || reason.response.status === 403)) document.location.assign('/');
        }).finally(() => {
          setLoadingBooks(false);
        });
    }
    else {
      setBooks([]);
    }
  }, [selectedBooksInstance]);

  const appContext = useContext(AppContext);
  useEffect(() => {
    appContext.setTitle('Upload des i-Manuels');
  }, [appContext]);

  useEffect(() => {
    if (selectedFile && books) {
      setSelectedBook(undefined);
      setInfoMessage(undefined);
      setErrors([]);
      var reader = new FileReader();

      reader.addEventListener("load", function () {
        console.log(reader.result);

        const parser = new DOMParser();

        let xmlContent = reader.result as string;
        if (xmlContent) {
          // Fix nbsp & thinsp
          xmlContent = xmlContent.replaceAll("&nbsp;", "&#160;");
          xmlContent = xmlContent.replaceAll("&thinsp;", "&#160;");
        }
        const doc = parser.parseFromString(xmlContent, "text/xml");

        if (doc) {
          const parsedBook = BookParser.getBook(doc);
          if (parsedBook) {
            const existingReferences = books.filter((b) => b.id === parsedBook.id || b.shortCode === parsedBook.shortCode);
            if (existingReferences.length > 1)
              setErrors(["Données incohérentes: il existe plusieurs références avec cet EAN ou ce code court"]);
            else if (existingReferences.length === 1 && (existingReferences[0].id !== parsedBook.id || existingReferences[0].shortCode !== parsedBook.shortCode)) {
              setErrors([`Données incohérentes: il existe une référence mais donc l'EAN et le code court ne correspondent pas (${existingReferences[0].id} / ${existingReferences[0].shortCode})`]);
            }
            else {
              setSelectedBook(parsedBook);
            }
          }
          else {
            setErrors(["Fichier XML invalide"]);
          }
        }
        else {
          setErrors(["Fichier XML invalide"]);
        }
      }, false);

      reader.readAsText(selectedFile);
    }
  }, [selectedFile, books]);

  useEffect(() => {
    if (selectedBook && selectedBooksInstance) {
      CommonApi.getBookUploadInfo(selectedBooksInstance, selectedBook.id)
        .then((res) => {
          setUploadInfo(res.data);
        })
        .catch(() => {
          setErrors([`Impossible d'obtenir l'URL d'upload pour ce manuel.`]);
        });
    }
  }, [selectedBook, selectedBooksInstance]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      CommonApi.getBooksInstances()
        .then((resInstances) => {
          setBooksInstances(resInstances.data);
        });
    }
  }, [initialized]);

  useEffect(() => {
    if (selectedBooksInstance) {
      setLoadingBooks(true);
      setErrors([]);
      setUploadInfo(undefined);
      setSelectedBook(undefined);
      setSelectedFile(undefined);

      CommonApi.getBooks(selectedBooksInstance)
        .then((resBooks) => {
          resBooks.data.forEach(b => {
            // Remove HTML
            var tempElement = document.createElement("div");
            tempElement.innerHTML = b.name;
            b.name = tempElement.textContent || tempElement.innerText || "";
          });
          setBooks(resBooks.data);
        })
        .catch((reason) => {
          if (reason.response && (reason.response.status === 401 || reason.response.status === 403)) document.location.assign('/');
          setBooks(undefined);
        }).finally(() => {
          setLoadingBooks(false);
        });
    }
    else {
      setBooks([]);
    }
  }, [selectedBooksInstance]);

  return (
    <Box px={4}>
      <Grid container direction="column" item sm={12} md={8} lg={10} xl={10} spacing={3}>
        <Grid item xs>
          <Autocomplete
            id="booksInstances"
            disabled={!booksInstances}
            options={booksInstances || []}
            value={selectedBooksInstance || null}
            onChange={(event, bookInstanceValue) => handleBooksInstanceChange(bookInstanceValue ?? undefined)}
            noOptionsText="Aucune instance"
            renderInput={(params: any) => (
              <TextField {...params} label="Instances" placeholder="Sélectionner une instance i-Manuel" />
            )}
          />
        </Grid>
        {selectedBooksInstance && books && !loadingBooks && (
          <Grid item xs>
            <Typography>Cette instance comporte {books.length} i-Manuels.</Typography>
            <label htmlFor="book-file">
              <input id="book-file" type="file" style={{ display: 'none' }} accept=".xml" onChange={(ev: any) => {
                if (ev.target && ev.target.files && ev.target.files.length === 1)
                  setSelectedFile((ev.target as any).files[0]);
                else
                  setSelectedFile(undefined);
              }} />
              <Button variant="contained" component="span" color="primary" style={{ marginTop: 5, marginBottom: 5 }}>
                Charger le fichier XML depuis votre poste
              </Button>
            </label>
            {selectedFile && selectedBook && (
              <Typography>Fichier chargé : {selectedFile.name}</Typography>
            )}
            {errors && errors.map((err, idx) => {
              return (<Alert severity="error" key={`err_${idx}`} className={styleClasses.alert}>{err}</Alert>)
            })}
          </Grid>
        )}
        <Grid item xs style={{ paddingTop: 20 }}>
          {
            infoMessage && (
              <Alert variant="filled" severity="success" onClose={() => setInfoMessage(undefined)}>{infoMessage}</Alert>
            )
          }
          {selectedBooksInstance && books && selectedFile && selectedBook && uploadInfo && books.findIndex((b) => b.id === selectedBook.id) < 0 && (
            <AddBook instance={selectedBooksInstance} uploadInfo={uploadInfo} book={selectedBook} bookFile={selectedFile} bookAdded={bookAdded} />
          )}
          {selectedBooksInstance && books && selectedFile && selectedBook && uploadInfo && books.findIndex((b) => b.id === selectedBook.id) >= 0 && (
            <EditBook uploadInfo={uploadInfo} book={selectedBook} bookInstance={selectedBooksInstance} setInfoMessage={setInfoMessage} />
          )}
          {loadingBooks && <CircularProgress />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
