import React, { useState } from "react";
import ChangeUserPassword from '../changeUserPassword/ChangeUserPassword';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import UserIcon from '@material-ui/icons/Person';
import ChangePasswordIcon from '@material-ui/icons/LockOpen';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import User from '../../models/User';
import DeleteUser from '../deleteUser/DeleteUser';

interface UsersListProps {
    refreshUsers: () => void;
    users: User[];
}

const UsersList: React.FC<UsersListProps> = (props: UsersListProps) => {
    const [selectedUserForChangePassword, setSelectedUserForChangePassword] = useState<User>();
    const [selectedUserForDelete, setSelectedUserForDelete] = useState<User>();

    const changePasswordCanceled = () => {
        console.log('changePasswordCanceled');
        setSelectedUserForChangePassword(undefined);
    };

    const changePasswordDone = () => {
        console.log('changePasswordDone');
        setSelectedUserForChangePassword(undefined);
    };

    const deleteUserCanceled = () => {
        console.log('deleteUserCanceled');
        setSelectedUserForDelete(undefined);
    };

    const deleteUserDone = () => {
        console.log('deleteUserDone');
        setSelectedUserForDelete(undefined);
        props.refreshUsers();
    };

    return (
        <Grid item>
            <List>
                {props.users.map((u) => {
                    return (
                        <ListItem key={u.username} divider>
                            <ListItemAvatar>
                                <Avatar>
                                    <UserIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={u.username}
                                secondary={u.email}
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="Changer le mot de passe" onClick={() => setSelectedUserForChangePassword(u)}>
                                    <ChangePasswordIcon titleAccess="Changer le mot de passe" />
                                </IconButton>
                                <IconButton edge="end" aria-label="Supprimer le compte" onClick={() => setSelectedUserForDelete(u)}>
                                    <DeleteIcon titleAccess="Supprimer le compte" />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)
                }
                )}
            </List>
            <ChangeUserPassword selectedUser={selectedUserForChangePassword} canceled={changePasswordCanceled} done={changePasswordDone} />
            <DeleteUser selectedUser={selectedUserForDelete} canceled={deleteUserCanceled} done={deleteUserDone} />
        </Grid>
    )
}

export default UsersList;