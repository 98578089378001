import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, TextField, Grid, Button, Typography, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import useStyles from '../common/UseStyles';
import CommonApi from "../api/CommonApi";
import Book from "../models/Book";
import AppContext from "../contexts/AppContext";

export interface BookSelectorProps {
    title: string;
    href: string;
    button: string;
}

const BookSelector: React.FC<BookSelectorProps> = (props: BookSelectorProps) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [booksInstances, setBooksInstances] = useState<string[]>();
  const [selectedBooksInstance, setSelectedBooksInstance] = useState<string>();
  const [books, setBooks] = useState<Book[]>();
  const [selectedBook, setSelectedBook] = useState<Book>();
  const [loadingBooks, setLoadingBooks] = useState<boolean>(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errors, setErrors] = useState<string[]>();
  const styleClasses = useStyles();

  const appContext = useContext(AppContext);
  useEffect(() => {
    appContext.setTitle(props.title);
  }, [appContext, props.title]);

  const handleBooksInstanceChange = (instance: string | undefined) => {
    console.log("handleBooksInstanceChange");
    setSelectedBook(undefined);
    setBooks([]);
    setInfoMessage(undefined);
    setSelectedBooksInstance(instance);
  };

  const handleBookChange = (book: Book | undefined) => {
    console.log("handleBookChange");
    setSelectedBook(undefined);
    setSelectedBook(book);
  };

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      CommonApi.getBooksInstances()
        .then((resInstances) => {
          setBooksInstances(resInstances.data);
        });
    }
  }, [initialized]);

  useEffect(() => {
    if (selectedBooksInstance) {
      setLoadingBooks(true);
      setErrors([]);
      setSelectedBook(undefined);

      CommonApi.getBooks(selectedBooksInstance)
        .then((resBooks) => {
          resBooks.data.forEach(b => {
            // Remove HTML
            var tempElement = document.createElement("div");
            tempElement.innerHTML = b.name;
            b.name = tempElement.textContent || tempElement.innerText || "";
          });
          setBooks(resBooks.data);
        })
        .catch((reason) => {
          if (reason.response && (reason.response.status === 401 || reason.response.status === 403)) document.location.assign('/');
          setBooks(undefined);
        }).finally(() => {
          setLoadingBooks(false);
        });
    }
    else {
      setBooks([]);
    }
  }, [selectedBooksInstance]);

  return (
    <Box px={4}>
      <Grid container direction="column" item sm={12} md={8} lg={10} xl={10} spacing={3}>
        <Grid item xs>
          <Autocomplete
            id="booksInstances"
            disabled={!booksInstances}
            options={booksInstances || []}
            value={selectedBooksInstance || null}
            onChange={(event, bookInstanceValue) => handleBooksInstanceChange(bookInstanceValue ?? undefined)}
            noOptionsText="Aucune instance"
            renderInput={(params: any) => (
              <TextField {...params} label="Instances" placeholder="Sélectionner une instance i-Manuel" />
            )}
          />
        </Grid>
        {selectedBooksInstance && books && !loadingBooks && (
          <Grid item xs>
            <Typography>Cette instance comporte {books.length} i-Manuels.</Typography>
            {errors && errors.map((err, idx) => {
              return (<Alert severity="error" key={`err_${idx}`} className={styleClasses.alert}>{err}</Alert>)
            })}
          </Grid>
        )}
        <Grid item xs style={{ paddingTop: 20 }}>
          {
            infoMessage && (
              <Alert variant="filled" severity="success" onClose={() => setInfoMessage(undefined)}>{infoMessage}</Alert>
            )
          }
          {selectedBooksInstance && books && (
            <Autocomplete
              id="books"
              filterSelectedOptions
              disabled={loadingBooks}
              options={books}
              value={selectedBook || null}
              onChange={(event, bookValue) => handleBookChange(bookValue ?? undefined)}
              getOptionLabel={(option: any) => `${option.name} - ${option.id}`}
              noOptionsText="Aucun manuel"
              renderInput={(params: any) => (
                <TextField {...params} label="Manuels" placeholder="Sélectionner un manuel" />
              )}
            />
          )}
          {selectedBook && (
                      <Button component={RouterLink} to={`${props.href}/${selectedBooksInstance}/${selectedBook.id}`} variant="contained" color="primary" style={{ marginTop: 5, marginBottom: 5 }} disabled={(!selectedBook)}>{props.button}</Button>
          )}
          {loadingBooks && <CircularProgress />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookSelector;
