import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import User from "../../models/User";
import UsersList from '../../components/usersList/UsersList';
import CreateUser from '../../components/createUser/CreateUser';
import UsersManagementApi from "../../api/UsersManagementApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppContext from "../../contexts/AppContext";

export interface AdminUsersProps { }

const AdminUsers: React.FC<AdminUsersProps> = (props: AdminUsersProps) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);

  const appContext = useContext(AppContext);
  useEffect(() => {
    appContext.setTitle('Gestions des utilisateurs');
  }, [appContext]);

  const refreshUsers = () => {
    UsersManagementApi.getUsers()
      .then((res) => {
        setUsers(res.data);
      })
  };

  useEffect(() => {
    if (!initialized) {
      UsersManagementApi.getUsers()
        .then((res) => {
          setInitialized(true);
          setUsers(res.data);
        })
    }
  }, [initialized]);

  return (
    <Grid
      container
      spacing={3}>
      {!initialized && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {initialized && (
        <Grid item xs={12}>
          <CreateUser userCreated={refreshUsers} />
          <UsersList users={users} refreshUsers={refreshUsers} />
        </Grid>
      )}
    </Grid>
  );
};

export default AdminUsers;
