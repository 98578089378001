import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import User from '../../models/User';
import generatePassword from '../../api/PasswordGenerator';
import UsersManagementApi from '../../api/UsersManagementApi';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import useStyles from '../../common/UseStyles';

interface ChangeUserPasswordProps {
    done: () => void;
    canceled: () => void;
    selectedUser: User | undefined;
}

const ChangeUserPassword: React.FC<ChangeUserPasswordProps> = (props: ChangeUserPasswordProps) => {
    const [password, setPassword] = useState<string>();
    const [errors, setErrors] = useState<string[]>();
    const styleClasses = useStyles();

    useEffect(() => {
        if (props.selectedUser){
            setPassword(generatePassword(10));
        }
    }, [props.selectedUser])
    
    const closeDialog = () => {
        props.done();
    }
    
    const updatePassword = () => {
        setErrors([]);
        if (props.selectedUser)
        {
            UsersManagementApi.changeUserPassword({ 
                username: props.selectedUser.username, 
                password: password ?? '' 
            }).then((res) => {
                if (res.data.length > 0) {
                    if (res.data[0] === 'Success') {                        
                        props.done();
                    }
                    else {
                        let errorMessages: string[] = [];
                        res.data.forEach((err) => {
                            switch (err) {
                                case 'DefaultError':
                                case 'ConcurrencyFailure':
                                    errorMessages.push('Une erreur est survenue');
                                    break;
                                case 'PasswordMismatch':
                                    errorMessages.push('Les mots de passe ne correspondent pas');
                                    break;
                                case 'LoginAlreadyAssociated':
                                case 'DuplicateUserName':
                                    errorMessages.push('Identifiant déjà utilisé');
                                    break;
                                case 'InvalidUserName':
                                    errorMessages.push('Identifiant invalide');
                                    break;
                                case 'InvalidEmail':
                                    errorMessages.push('Adresse email invalide');
                                    break;
                                case 'DuplicateEmail':
                                    errorMessages.push('Adresse email déjà utilisée');
                                    break;
                                case 'PasswordTooShort':
                                    errorMessages.push('Le mot de passe est trop court');
                                    break;
                                case 'PasswordRequiresNonAlphanumeric':
                                    errorMessages.push('Le mot de passe doit contenir au moins un caractère non alphanumérique');
                                    break;
                                case 'PasswordRequiresDigit':
                                    errorMessages.push('Le mot de passe doit contenir au moins un caractère numérique');
                                    break;
                                case 'PasswordRequiresLower':
                                    errorMessages.push('Le mot de passe doit contenir au moins une minuscule');
                                    break;
                                case 'PasswordRequiresUpper':
                                    errorMessages.push('Le mot de passe doit contenir au moins une majuscule');
                                    break;
                                default:
                                    errorMessages.push('Une erreur est survenue');
                                    break;
                            }
                        });
    
                        setErrors(errorMessages);
                    }
                }
            });
        }
    }

    return (
        <Dialog open={props.selectedUser !== undefined} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Mise à jour du mot de passe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Génération d'un nouveau mot de passe pour l'utilisateur <b>{props.selectedUser?.username}</b>
                </DialogContentText>                
                <TextField
                    margin="normal"
                    id="password"
                    value={password}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                    label="Nouveau mot de passe"
                    type="text"
                    fullWidth
                />
                
                {errors && errors.map((err) => {
                        return (<Alert severity="error" className={styleClasses.alert}>{err}</Alert>)
                    })}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="secondary">Annuler</Button>
                <Button onClick={updatePassword} color="primary">Modifier</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangeUserPassword;