import React from "react";
import useStyles from '../../common/UseStyles';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

export interface LogoutProps { }

const Logout: React.FC<LogoutProps> = (props: LogoutProps) => {    
    const login = () => {
        document.location.assign('/Identity/Account/Login?returnUrl=/')
    };
    const styleClasses = useStyles();
    
    return (
        <Container maxWidth='xs' className={styleClasses.logoutContainer}>
            <Typography variant="h5">Vous avez été déconnecté.</Typography>
            <Button variant="contained" color="primary" onClick={login} style={{marginTop: 50}}>Se reconnecter</Button>
        </Container>
    );
};

export default Logout;
