import axios, { AxiosResponse } from 'axios';
import { ContainerClient} from '@azure/storage-blob';
import User from '../models/User';
import Book from '../models/Book';
import UploadInfo from '../models/UploadInfo';

class CommonApi {
    public static getCurrentUser(): Promise<AxiosResponse<User>> {
        return axios.get<User>(`/api/users/current`);
    }

    public static getBooksInstances(): Promise<AxiosResponse<string[]>> {
        return axios.get<string[]>(`/api/books`, {});
    }

    public static getBooks(instance: string): Promise<AxiosResponse<Book[]>> {
        return axios.get<Book[]>(`/api/books/${instance}`, {});
    }

    public static getBookUploadInfo(instance: string, id: string): Promise<AxiosResponse<UploadInfo>> {
        return axios.get<UploadInfo>(`/api/books/${instance}/${id}/upload`, {});
    }

    public static uploadFile(containerClient: ContainerClient, file: File) {
        // create blobClient for container
        const blobClient = containerClient.getBlockBlobClient(file.name);
        
        // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: file.type } };
        
        // upload file
        return blobClient.uploadData(file, options);                    
    }

    public static addBook(instance: string, book: Book) : Promise<AxiosResponse<Book>> {
        let formData = new FormData();
        formData.append("Id", book.id);
        formData.append("Name", book.name);
        formData.append("ShortCode", book.shortCode);
        formData.append("CoverUrl", book.coverUrl);
        const headers = {"Content-Type": "multipart/form-data"};
        return axios.post<Book>(`/api/books/${instance}`, formData, { headers });
    }

    public static editBook(instance: string, book: Book): Promise<AxiosResponse<Book>> {
        let formData = new FormData();
        formData.append("Id", book.id);
        formData.append("Name", book.name);
        formData.append("ShortCode", book.shortCode);
        formData.append("CoverUrl", book.coverUrl);
        const headers = {"Content-Type": "multipart/form-data"};
        return axios.post<Book>(`/api/books/${instance}/${book.id}`, formData, { headers });
    }
    
    public static resetBookCache(instance: string, book: Book): Promise<AxiosResponse<boolean>> {        
        return axios.get<boolean>(`/api/books/${instance}/${book.id}/resetcache`);
    }
}

export default CommonApi;
