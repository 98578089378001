export default class ErrorHelper {
    // Return Error messages based on error codes
    public static getErrorMessages(errorCodes: string[]): string[] {
        let errorMessages: string[] = [];

        errorCodes.forEach((err) => {
            switch (err) {
                case 'DefaultError':
                case 'ConcurrencyFailure':
                    errorMessages.push('Une erreur est survenue');
                    break;
                case 'PasswordMismatch':
                    errorMessages.push('Les mots de passe ne correspondent pas');
                    break;
                case 'LoginAlreadyAssociated':
                case 'DuplicateUserName':
                    errorMessages.push('Identifiant déjà utilisé');
                    break;
                case 'InvalidUserName':
                    errorMessages.push('Identifiant invalide');
                    break;
                case 'InvalidEmail':
                    errorMessages.push('Adresse email invalide');
                    break;
                case 'DuplicateEmail':
                    errorMessages.push('Adresse email déjà utilisée');
                    break;
                case 'PasswordTooShort':
                    errorMessages.push('Le mot de passe est trop court');
                    break;
                case 'PasswordRequiresNonAlphanumeric':
                    errorMessages.push('Le mot de passe doit contenir au moins un caractère non alphanumérique');
                    break;
                case 'PasswordRequiresDigit':
                    errorMessages.push('Le mot de passe doit contenir au moins un caractère numérique');
                    break;
                case 'PasswordRequiresLower':
                    errorMessages.push('Le mot de passe doit contenir au moins une minuscule');
                    break;
                case 'PasswordRequiresUpper':
                    errorMessages.push('Le mot de passe doit contenir au moins une majuscule');
                    break;
                default:
                    errorMessages.push('Une erreur est survenue');
                    break;
            }
        });

        return errorMessages;
    }
}