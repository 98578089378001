import React from "react";

export interface LoginProps {}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  document.location.assign("/Identity/Account/Login?returnUrl=/");
  
  return (
    <div></div>
  );
};

export default Login;
