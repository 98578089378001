import React, { useEffect, useContext } from "react";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import AppContext from "../../contexts/AppContext";

export interface MetaProps { }

type MetaParams = {
    instance: string;
    bookId: string;
}

const Meta: React.FC<MetaProps> = (props: MetaProps) => {
    const { instance, bookId } = useParams<MetaParams>();

    const appContext = useContext(AppContext);
    useEffect(() => {
        appContext.setTitle('Gestion des métadonnées');
    }, [appContext]);

    return (
        <Box px={4}>
            <meta-widget src={`/api/meta/${instance}/${bookId}`}></meta-widget>
        </Box>
    );
};

export default Meta;
