import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AppContextProvider } from './contexts/AppContext';
import User from './models/User';
import AuthenticatedRoute from './routes/authenticated/AuthenticatedRoute';
import TopBar from './components/topBar/TopBar';
import Footer from './components/footer/Footer';
import Login from './routes/login/Login';
import Logout from './routes/logout/Logout';
import Home from './routes/home/Home';
import BookSelector from './routes/BookSelector';
import Timesheet from './routes/timesheet/Timesheet';
import Meta from './routes/meta/Meta';
import AdminUsers from './routes/adminUsers/AdminUsers';
import CommonApi from './api/CommonApi';
import useStyles from './common/UseStyles';
import './App.css';
import Container from '@material-ui/core/Container';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d32f2f",
    },
    secondary: {
      main: "#b0bec5"
    }
  },
});

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'app-icon': { type: string; };
      'timesheet-widget': { src: string; };
      'meta-widget': { src: string; };
    }
  }
}

const App: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [title, setTitle] = useState<String>('Upload des i-Manuels');
  const [appLoaded, setAppLoaded] = useState<boolean>();
  const styleClasses = useStyles();

  useEffect(() => {
    if (!appLoaded) {
      CommonApi.getCurrentUser().then((usr) => {
        setCurrentUser(usr.data);
      }).catch((reason) => {
        console.log(reason);
        if (reason.response && (reason.response.status === 401 || reason.response.status === 403)) document.location.assign('/');
      }).finally(() => {
        setAppLoaded(true);
      });
    }
  }, [appLoaded]);

  return (
    <AppContextProvider value={{ currentUser, setCurrentUser, title, setTitle }}>
      {appLoaded && (
        <ThemeProvider theme={theme}>
          <Router>
            <TopBar />
            <Container className={styleClasses.container} id="maincontainer" maxWidth="xl">
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/logout">
                  <Logout />
                </Route>
                <Route exact path="/">
                  <Redirect to={{ pathname: "/app" }} />
                </Route>
                <AuthenticatedRoute path="/app">
                  <Home />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/timesheet/:instance/:bookId">
                  <Timesheet />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/timesheet">
                  <BookSelector title="Gestion du temps" href="/timesheet" button="D&eacute;clarer les temps" />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/meta/:instance/:bookId">
                  <Meta />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/meta">
                  <BookSelector title="Gestion des m&eacute;tadonn&eacute;es" href="/meta" button="D&eacute;clarer les m&eacute;tadonn&eacute;es" />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/admin/users">
                  <AdminUsers />
                </AuthenticatedRoute>
              </Switch>
            </Container>
            <Footer />
          </Router>
        </ThemeProvider>
      )}
    </AppContextProvider>
  );
};

export default App;
