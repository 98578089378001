import axios, { AxiosResponse } from 'axios';
import User from '../models/User';
import CreateUser from '../models/CreateUser';
import ChangeUserPassword from '../models/ChangeUserPassword';

class UsersManagementApi {
    public static getCurrentUser(): Promise<AxiosResponse<User>> {
        return axios.get<User>(`/api/users/current`);
    }

    public static getUsers(): Promise<AxiosResponse<User[]>> {
        return axios.get<User[]>(`/api/users`);
    }
    
    public static getUser(username:string): Promise<AxiosResponse<User>> {
        return axios.get<User>(`/api/users/${encodeURIComponent(username)}`);
    }
    
    public static createUser(user: CreateUser): Promise<AxiosResponse<string[]>> {
        return axios.post<string[]>(`/api/users`, user);
    }

    public static deleteUser(username: string): Promise<AxiosResponse<string[]>> {
        return axios.delete<string[]>(`/api/users/${encodeURIComponent(username)}`);
    }

    public static changeUserPassword(user: ChangeUserPassword): Promise<AxiosResponse<string[]>> {
        return axios.post<string[]>(`/api/users/changepassword`, user);
    }
}

export default UsersManagementApi;
