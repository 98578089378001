import { useEffect, useState } from 'react';
import { ContainerClient } from '@azure/storage-blob';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert/Alert';
import useStyles from '../../common/UseStyles';
import CommonApi from '../../api/CommonApi';
import Typography from '@material-ui/core/Typography';
import Book from '../../models/Book';
import UploadInfo from '../../models/UploadInfo';
import { FormControl } from '@material-ui/core';

interface AddBookProps {
    instance: string;
    book: Book;
    bookFile: File;
    uploadInfo: UploadInfo;
    bookAdded: (book: Book) => void;
}

const AddBook = (props: AddBookProps) => {
    const { instance, book, uploadInfo, bookFile, bookAdded } = props;
    const [containerClient, setContainerClient] = useState<ContainerClient>();
    const [errors, setErrors] = useState<string[]>();
    const styleClasses = useStyles();

    const create = () => {
        setErrors([]);
        if (containerClient && bookFile) {
            CommonApi.uploadFile(containerClient, bookFile)
                .then((fileUpload) => {
                    CommonApi.addBook(instance, book).then((res) => {
                        bookAdded(book);
                    });
                })
                .catch(() => {
                    setErrors(['Impossible de créer le manuel']);
                });
        }
    };

    useEffect(() => {
        setContainerClient(uploadInfo ? new ContainerClient(`${uploadInfo.baseUrl}${uploadInfo.query}`) : undefined);
    }, [uploadInfo]);

    return (
        <FormControl fullWidth>
            <Typography>Cet i-Manuel n'existe pas dans la base de données. Vérifiez les informations suivantes et validez pour l'ajouter au référentiel.</Typography>
            <TextField
                margin="normal"
                id="name"
                value={book.name}
                disabled
                label="Titre du i-Manuel"
                type="text"
                fullWidth
            />
            <TextField
                margin="normal"
                id="ean"
                value={book.id}
                label="EAN"
                disabled
                type="text"
                fullWidth
            />
            <TextField
                margin="normal"
                id="shortCode"
                value={book.shortCode}
                label="Code court"
                disabled
                type="text"
                fullWidth
            />
            <Button onClick={create} variant="contained" color="primary" disabled={(!containerClient || !bookFile)}>Ajouter cet i-Manuel</Button>
            {errors && errors.map((err) => {
                return (<Alert severity="error" className={styleClasses.alert}>{err}</Alert>)
            })}
        </FormControl>
    );
}

export default AddBook;