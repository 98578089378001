import React from "react";
import IUser from "../models/User";

export interface IAppContext {
  currentUser: IUser | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  title: String;
  setTitle: React.Dispatch<React.SetStateAction<String>>;
}

const AppContext: React.Context<IAppContext> = React.createContext({
  currentUser: undefined,
  setCurrentUser: () => {},
  title: '',
  setTitle: () => { },
} as IAppContext);

export const AppContextProvider = AppContext.Provider;

export default AppContext;
